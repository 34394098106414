import Vue from 'vue'
import VueRouter from 'vue-router'
import * as Web3Utils from 'web3-utils'
import Store from '../store'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    async beforeEnter(to, from, next) {
      try {
        // eslint-disable-next-line
        gtag('event', 'screen_view', { page: 'Home' })
      } catch (e) {
        // Do nothing
      }
      return next()
    },
  },
  {
    path: '/x1val-online',
    name: 'x1val-online',
    component: () => import(/* webpackChunkName: "x1val-online" */ '../views/X1valOnline.vue'),
    async beforeEnter(to, from, next) {
      await Store.dispatch('fetchX1valOnline')
      try {
        // eslint-disable-next-line
        gtag('event', 'screen_view', { page: 'X1valOnline' })
      } catch (e) {
        // Do nothing
      }
      return next()
    },
  },
  {
    path: '/change-log',
    name: 'change-log',
    component: () => import(/* webpackChunkName: "change-log" */ '../views/ChangeLog.vue'),
    async beforeEnter(to, from, next) {
      try {
        // eslint-disable-next-line
        gtag('event', 'screen_view', { page: 'ChangeLog' })
      } catch (e) {
        // Do nothing
      }
      return next()
    },
  },
  {
    path: '/:account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../views/AccountView.vue'),
    async beforeEnter(to, from, next) {
      const account = to.params.account
      if (Web3Utils.isAddress(account)) {
        await Store.dispatch('fetchNetworkAccount', account)
        await Store.dispatch('fetchWorkers', account)
        await Store.dispatch('fetchBootingWorkers', account)
        Store.commit('SetUserAccountSelected', account)

        try {
          // eslint-disable-next-line
          gtag('set', { account })
          // eslint-disable-next-line
          gtag('event', 'screen_view', { page: 'Account', account })
        } catch (e) {
          // Do nothing
        }

        return next()
      }

      Store.commit('setSnackbar', {
        visible: true,
        text: `This account is not a valid Web3: ${account}`,
        timeout: 10,
      })

      next('/')
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
