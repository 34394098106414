<template>
  <v-container>
    <h1 class="pa-5 text-center">XENGPUMiner installation and monitoring</h1>

    <v-card>
      <v-card-title>
        Github
        <a class="ml-2" href="https://github.com/samotpoint/XENGPUMiner-monitoring" target="_blank">
          XENGPUMiner-monitoring
        </a>
      </v-card-title>

      <v-card-subtitle>
        Starting today (April 14th, 2024) we will be collecting a dev fee of 1.6% which is the last
        minute of every hour.
      </v-card-subtitle>

      <v-card-actions>
        <v-tabs v-model="currentTab" bg-color="primary">
          <v-tab>Vast AI</v-tab>
          <v-tab>PC/Mining Rig</v-tab>
        </v-tabs>
      </v-card-actions>

      <template v-if="currentTab === 0">
        <v-card-title>
          <div>
            Official xenblocks.app Vast.ai template
            <a
              href="https://cloud.vast.ai/?ref_id=90806&creator_id=90806&name=XENBLOCKS.APP%20OFFICIAL"
              target="_blank"
            >
              cloud.vast.ai
            </a>
          </div>
          <em class="ml-2 body-1">(Do not forget to update the ACCOUNT variable for yours)</em>
        </v-card-title>
      </template>

      <template v-if="currentTab === 1">
        <v-card-title class="mt-5">
          Assuming you are running Ubuntu and have already installed CUDA drivers.
        </v-card-title>

        <v-card-subtitle>
          The installation script will check for an environment variable ACCOUNT and if it does not
          exist it will prompt the user to enter the account. To paste your account (on linux ctrl +
          shift + v, on macOS, cmd + v)
          <br />
          CUDA Arch is automatically detected from an nvidia-smi command.
        </v-card-subtitle>

        <v-row v-for="instruction in instructions" :key="instruction.description" class="pa-5">
          <v-col cols="6" class="hidden-sm-and-down">
            <div class="body-1">
              {{ instruction.description }}
            </div>
          </v-col>
          <v-col cols="6">
            <v-btn
              large
              max-width="400"
              width="80vw"
              @click="onClipboard({ value: instruction.command })"
            >
              <v-icon left> mdi-content-copy</v-icon>
              {{
                instruction.command.length > 30
                  ? instruction.command.slice(0, 30) + '...'
                  : instruction.command
              }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card>

    <div>
      <div>
        Halving in <span class="title">{{ nextHalving | futureDateToDuration }}</span>
      </div>
      <div>
        Next halving at <span class="title">{{ nextHalving | fullDateFormat }}</span>
      </div>
      <div>
        Genesis at <span class="title">{{ startingDate | fullDateFormat }}</span>
      </div>
    </div>

    <v-card class="mt-10 mb-10">
      <v-card-title>
        Monitored Accounts<small class="ml-2"><em>(Updated once per minute)</em></small>
        <v-spacer></v-spacer>
        <div>Difficulty: {{ currentDifficulty }}</div>
      </v-card-title>
      <v-card-title>
        <v-list-item>
          <div>Accounts: {{ monitoredAccountStats.accounts }}</div>
          <v-spacer></v-spacer>
          <div>Workers: {{ monitoredAccountStats.workerCount }}</div>
          <v-spacer></v-spacer>
          <div>GPU: {{ monitoredAccountStats.gpusCount }}</div>
          <v-spacer></v-spacer>
          <div>Hash (kH/s): {{ monitoredAccountStats.hashRates }}</div>
        </v-list-item>
      </v-card-title>
      <v-data-table
        style="cursor: pointer"
        :mobile-breakpoint="0"
        :headers="headers1"
        :items="monitoredAccounts"
        :items-per-page="-1"
        :footer-props="{ itemsPerPageOptions: [15, 100, -1] }"
        @click:row="({ account }) => onNavigate(`/${account}`)"
        :dense="$vuetify.breakpoint.mdAndDown"
      >
        <template v-slot:[`item.account`]="{ item }">
          <span @click.stop="">
            <a :href="`/${item.account}`" target="_blank">
              <v-btn text>
                <v-icon class="ml-2 mr-2">mdi-open-in-new</v-icon>
                {{ item.account | accountBiggerFormat }}
              </v-btn>
            </a>
          </span>
        </template>
      </v-data-table>
    </v-card>

    <v-card id="gpu-stats" class="mt-10 mb-10">
      <v-card-title>
        GPU Stats
        <small class="ml-2"><em>(Updated once per minute)</em></small>
      </v-card-title>
      <v-data-table
        class="title"
        :mobile-breakpoint="0"
        :headers="headers2"
        :items="gpuStats"
        :items-per-page="-1"
        :footer-props="{ itemsPerPageOptions: [15, 100, -1] }"
      >
      </v-data-table>
    </v-card>

    <v-card class="mt-10">
      <v-card-title>
        Official XenBlocks Leaderboard
        <v-spacer></v-spacer>
        <a href="https://explorer.xenblocks.io/leaderboard">
          https://explorer.xenblocks.io/leaderboard
        </a>
        <!--        <v-autocomplete-->
        <!--          v-model="accounts"-->
        <!--          append-icon="mdi-filter"-->
        <!--          :items="tableAccounts.map(({ account }) => account)"-->
        <!--          :menu-props="{ maxHeight: '50vh' }"-->
        <!--          label="Filter by accounts"-->
        <!--          chips-->
        <!--          clearable-->
        <!--          multiple-->
        <!--          hide-details-->
        <!--          solo-->
        <!--        >-->
        <!--        </v-autocomplete>-->
      </v-card-title>
      <!--      <v-data-table-->
      <!--        style="cursor: pointer"-->
      <!--        :mobile-breakpoint="0"-->
      <!--        :headers="headers3"-->
      <!--        :items="accountsFiltered"-->
      <!--        :items-per-page="10"-->
      <!--        :footer-props="{ itemsPerPageOptions: [10, 100, -1] }"-->
      <!--        @click:row="({ account }) => onClipboard({ value: account })"-->
      <!--        :dense="$vuetify.breakpoint.mdAndDown"-->
      <!--      >-->
      <!--        <template v-slot:[`item.address`]="{ item }">-->
      <!--          <v-btn text @click="onClipboard({ value: item.account })">-->
      <!--            <v-icon left>mdi-content-copy</v-icon>-->
      <!--            {{ item.address }}-->
      <!--          </v-btn>-->
      <!--        </template>-->
      <!--      </v-data-table>-->
    </v-card>

    <div class="mt-15" style="width: 100%">
      <div class="ma-5">If this was helpful consider</div>
      <a href="https://www.buymeacoffee.com/samotpoints">
        <img
          alt="Buy me a coffee"
          src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=samotpoints&button_colour=40DCA5&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
        />
      </a>
      <div class="ma-5">Donation (ERC20)</div>
      <v-btn style="text-transform: none" @click="onClipboard({ value: donationAccount })">
        <v-icon left>mdi-content-copy</v-icon>
        {{ donationAccount }}
      </v-btn>
    </div>

    <!--    <v-card class="mt-10">-->
    <!--      <div class="pa-5 title">-->
    <!--        We are planning on deploying a node validator on main net if you want to delegate/stake-->
    <!--        (more information will be available soon)-->
    <!--        <br />-->
    <!--        <a-->
    <!--          class="ml-2 mr-2"-->
    <!--          href="https://pwa-explorer.x1-testnet.xen.network/validator/0xb74daea1d0ea8dd862828073ffd4552df0aad7ba"-->
    <!--          target="_blank"-->
    <!--        >-->
    <!--          Xenblocks.App Validator ID 799-->
    <!--        </a>-->
    <!--        (on test net)-->
    <!--      </div>-->
    <!--    </v-card>-->

    <!--    <div class="mt-15 title">-->
    <!--      To see Node Validator list-->
    <!--      <v-btn style="text-transform: none" @click="onNavigate(`/x1val-online`)">-->
    <!--        <v-icon left>mdi-link</v-icon>-->
    <!--        /x1val-online-->
    <!--      </v-btn>-->
    <!--    </div>-->

    <div class="mt-15 title">
      To see Dev fee account
      <v-btn style="text-transform: none" @click="onNavigate(`/${devFeeAccount}`)">
        <v-icon left>mdi-link</v-icon>
        {{ devFeeAccount }}
      </v-btn>
    </div>

    <!--    <div class="mt-15 title">-->
    <!--      To see what's new on Xenblocks.app-->
    <!--      <v-btn @click="onNavigate('/change-log')">Open Change Log</v-btn>-->
    <!--    </div>-->

    <!--    <div class="mt-10">-->
    <!--      Most data are provided by-->
    <!--      <a href="https://hashhead.io/" target="_blank">hashhead.io</a>-->
    <!--      thanks to <strong>TreeCityWes.eth</strong>-->
    <!--    </div>-->
    <div class="mt-10">
      <em>This project is provided freely and it is to be used at your own risk.</em>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'HomeView',
  data() {
    return {
      currentTab: 0,
      donationAccount: '0xB74Daea1d0eA8DD862828073Ffd4552DF0aAD7bA',
      devFeeAccount: '0x59018CCc7851E3E6f0624316e5802dB96A2249E3',
      headers1: [
        // { text: 'Rank', value: 'rank', align: 'center' },
        { text: 'Account', value: 'account' },
        { text: 'Worker', value: 'workerCount', align: 'center' },
        { text: 'GPU', value: 'gpusCount', align: 'center' },
        { text: 'Hash (kH/s)', value: 'hashRates', align: 'center' },
        { text: 'Hash/GPU', value: 'hashRatePerGpus', align: 'center' },
      ],
      headers2: [
        { text: 'GPU', value: 'gpuName', align: 'right' },
        { text: 'AVG Hash (H/s)', value: 'avgHashRate', align: 'center' },
        { text: 'Total Hash (H/s)', value: 'totalHashRate', align: 'center' },
        { text: 'Count', value: 'count', align: 'center' },
      ],
      headers3: [
        { text: 'Rank', value: 'rank' },
        { text: 'Account', value: 'address' },
        { text: 'XNM', value: 'xnm', align: 'center' },
        { text: 'Blocks', value: 'total_blocks', align: 'center' },
        { text: 'XUNI', value: 'total_xuni', align: 'center' },
        { text: 'X.BLNK', value: 'super_blocks', align: 'center' },
        // { text: 'Daily Blocks', value: 'daily_blocks', align: 'center' },
      ],
      instructions: [
        {
          description: 'To install everything and start mining.',
          command:
            'sudo apt-get update &>/dev/null || apt-get update &>/dev/null && \\\n' +
            'sudo apt-get install -y git &>/dev/null || apt-get install -y sudo git &>/dev/null && \\\n' +
            'git clone https://github.com/samotpoint/XENGPUMiner-monitoring.git || echo "Skip cloning XENGPUMiner-monitoring" && \\\n' +
            'cd XENGPUMiner-monitoring && \\\n' +
            'sudo chmod -R 700 scripts && \\\n' +
            'scripts/boot.sh',
        },
        {
          description: 'To stop mining',
          command: 'scripts/stop.sh',
        },
        {
          description: 'To start mining (After you stopped)',
          command: 'scripts/start.sh && scripts/monitor.sh',
        },
        {
          description: 'To reset your setup (Fresh start)',
          command: 'scripts/reset.sh',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['networkAccounts', 'userAccounts', 'monitor', 'currentDifficulty']),
    startingDate() {
      // return new Date('2023-09-16T20:59:55.000Z')
      return new Date(1694897995 * 1000)
    },
    nextHalving() {
      const halvingDate = new Date(this.startingDate)
      while (halvingDate.getTime() < Date.now()) {
        halvingDate.setUTCDate(halvingDate.getUTCDate() + 366)
      }
      return halvingDate
    },
    tableAccounts() {
      return this.networkAccounts.map((a) => {
        return {
          ...a,
          address: this.$options.filters.accountFormat(a.account),
          xnm: Math.round(a.total_blocks * 10),
        }
      })
    },
    accountsFiltered() {
      if (this.accounts.length) {
        return this.tableAccounts.filter(({ account }) =>
          this.userAccounts.length ? this.userAccounts.includes(account) : true
        )
      }
      return this.tableAccounts
    },
    accounts: {
      get() {
        return this.userAccounts
      },
      set(value) {
        this.onSelectAccounts(value)
      },
    },
    monitoredAccountStats() {
      let stats = {
        workerCount: 0,
        hashRates: 0,
        gpusCount: 0,
        blockFoundCount: 0,
      }

      stats = this.monitor.accounts.reduce(
        (stat, { workerCount, hashRates, gpusCount, blockFoundCount }) => {
          stat.workerCount += workerCount
          stat.hashRates += hashRates
          stat.gpusCount += gpusCount
          stat.blockFoundCount += blockFoundCount
          return stat
        },
        stats
      )

      return {
        ...stats,
        accounts: this.monitor.accounts.length,
        hashRates: stats.hashRates?.toFixed(1),
      }
    },
    monitoredAccounts() {
      return this.monitor.accounts
        .map((monitorAccount) => {
          const account = monitorAccount.account
          const networkAccount = this.networkAccounts.find(
            (networkAccount) => networkAccount.account === account.toLocaleLowerCase()
          )
          return {
            account,
            ...monitorAccount,
            hashRates: monitorAccount.hashRates?.toFixed(1),
            hashRatePerGpus: (monitorAccount.hashRates / monitorAccount.gpusCount).toFixed(2),
            rank: networkAccount?.rank ?? 'N/A',
          }
        })
        .sort((a, b) => {
          return b.hashRates - a.hashRates
        })
    },
    gpuStats() {
      return this.monitor.gpuStats?.map((item) => ({
        ...item,
        gpuName: item.gpuName?.replace('NVIDIA', '')?.replace('GeForce', ''),
      }))
    },
  },
  async created() {
    await this.fetchMonitorAccountDetails()
  },
  methods: {
    ...mapMutations(['setUserAccounts']),
    ...mapActions(['fetchMonitorAccountDetails', 'onClipboard', 'navigate']),
    onSelectAccounts(value) {
      this.setUserAccounts(value)
    },
    onNavigate(path) {
      return this.$router.push(path)
    },
  },
}
</script>
