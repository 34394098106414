import Vue from 'vue'
import { format, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import { useVuetify } from '@/plugins/vuetify'

const dateIsValid = (date) => date instanceof Date && !isNaN(date)

Vue.filter('accountFormat', (value) => {
  const vuetify = useVuetify()
  return vuetify.breakpoint.mdAndDown ? `${value.slice(0, 4)}...${value.slice(-4)}` : value
})

Vue.filter('accountBiggerFormat', (value) => {
  const vuetify = useVuetify()
  return vuetify.breakpoint.mdAndDown ? `${value.slice(0, 6)}...${value.slice(-6)}` : value
})

Vue.filter('dateFormatDistanceToNow', (value) => {
  const date = new Date(value)
  if (!dateIsValid(date)) {
    return '-'
  }
  const result = formatDistanceToNow(date)
  if (result === 'almost 53 years') {
    return '-'
  }
  return result
})

Vue.filter('dateFormatDistanceToNowWithUnit', (value, unit) => {
  const date = new Date(value)
  if (!dateIsValid(date)) {
    return '-'
  }
  const result = formatDistanceToNowStrict(date, { unit })
  if (result === 'almost 53 years') {
    return '-'
  }

  return result
})

Vue.filter('timeFormat', (value) => {
  const date = new Date(value)
  if (!dateIsValid(date)) {
    return '-'
  }
  return format(date, 'h:mm a')
})

Vue.filter('secondsToMinutesSeconds', (value) => {
  if (value < 60) {
    return value
  }

  const seconds = `${value % 60}`.padStart(2, '0')
  const minutes = Math.floor(value / 60)

  return `${minutes}:${seconds}`
})

Vue.filter('secondsToHoursMinutesSeconds', (value) => {
  if (value < 60) {
    return value
  }

  const seconds = `${value % 60}`.padStart(2, '0')
  const minutes = Math.floor(value / 60)
  const minutesToDisplay = `${minutes % 60}`.padStart(2, '0')
  const hours = Math.floor(minutes / 60)

  return `${hours}:${minutesToDisplay}:${seconds}`
})

Vue.filter('dateToDuration', (value) => {
  const date = new Date(value)
  value = Math.floor((new Date().getTime() - date.getTime()) / 1000)

  if (value < 60) {
    return `${value} seconds`
  }

  const minutes = Math.floor(value / 60)
  const minutesToDisplay = `${minutes % 60}`.padStart(2, '0')
  const hours = Math.floor(minutes / 60)
  const hoursToDisplay = `${hours % 24}`.padStart(2, '0')
  const days = Math.floor(hours / 24)

  if (!hours) {
    return `${minutesToDisplay} minutes`
  }

  if (!days) {
    return `${hoursToDisplay}:${minutesToDisplay}`
  }

  return `${days} days, ${hoursToDisplay}:${minutesToDisplay}`
})

Vue.filter('futureDateToDuration', (value) => {
  const date = new Date(value)
  value = Math.floor((date.getTime() - new Date().getTime()) / 1000)

  if (value < 60) {
    return `${value} seconds`
  }

  const minutes = Math.floor(value / 60)
  const minutesToDisplay = `${minutes % 60}`.padStart(2, '0')
  const hours = Math.floor(minutes / 60)
  const hoursToDisplay = `${hours % 24}`.padStart(2, '0')
  const days = Math.floor(hours / 24)

  if (!hours) {
    return `${minutesToDisplay} minutes`
  }

  if (!days) {
    return `${hoursToDisplay}:${minutesToDisplay}`
  }

  return `${days} days, ${hoursToDisplay}:${minutesToDisplay}`
})

Vue.filter('dateToDurationShort', (value) => {
  const date = new Date(value)
  value = Math.floor((new Date().getTime() - date.getTime()) / 1000)

  if (value < 60) {
    return `${value} sec`
  }

  const minutes = Math.floor(value / 60)
  const minutesToDisplay = `${minutes % 60}`.padStart(2, '0')
  const hours = Math.floor(minutes / 60)
  const hoursToDisplay = `${hours % 24}`.padStart(2, '0')
  const days = Math.floor(hours / 24)

  if (!hours) {
    return `${minutesToDisplay} min`
  }

  if (!days) {
    return `${hoursToDisplay}:${minutesToDisplay}`
  }

  return `${days} days, ${hoursToDisplay} h`
})

Vue.filter('fullDateFormat', (value) => {
  const date = new Date(value)
  if (!dateIsValid(date)) {
    return '-'
  }
  return format(date, 'MMM d, h:mm a, yyyy')
})

Vue.filter('dateFormat', (value) => {
  const date = new Date(value)
  if (!dateIsValid(date)) {
    return '-'
  }
  return format(date, 'MMM d, h:mm a')
})
