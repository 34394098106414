import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import * as Web3Utils from 'web3-utils'
import router from '@/router'

const isProductionBuild = process.env.NODE_ENV === 'production'

Vue.config.devtools = !isProductionBuild

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user'],
    }),
  ],
  state: {
    serverOrigin: 'https://www.xenblocks.app',
    // serverOrigin: isProductionBuild ? 'https://www.xenblocks.app' : 'http://localhost:3000',
    snackbar: {
      visible: false,
      text: '',
      timeout: 3,
    },
    currentDifficulty: 0,
    user: {
      accounts: [],
      accountSelected: '',
    },
    monitor: {
      offlineWorkerEnabled: false,
      outdatedAfter: 3, // in minutes
      expireAfter: 15, // in minutes
      workers: [],
      bootingWorkers: [],
      accounts: [], // Monitored accounts
      accountDetails: [],
      gpuStats: [],
    },
    networkDetails: {},
    networkAccounts: [],
    x1valOnline: {
      timestamp: new Date(),
      data: [],
    },
  },
  getters: {
    snackbar(state) {
      return state.snackbar
    },
    currentDifficulty(state) {
      return state.currentDifficulty
    },
    networkDetails(state) {
      return state.networkDetails
    },
    networkAccounts(state) {
      return state.networkAccounts
    },
    userAccounts(state) {
      return state.user.accounts
    },
    userAccountSelected(state) {
      return state.user.accountSelected
    },
    userAccount(state) {
      return (
        state.networkAccounts.find(
          ({ account }) =>
            account.toLocaleLowerCase() === state.user.accountSelected.toLocaleLowerCase()
        ) || {}
      )
    },
    monitor(state) {
      return state.monitor
    },
    workers(state) {
      return state.monitor.workers
    },
    bootingWorkers(state) {
      return state.monitor.bootingWorkers
        .map((bootingWorker, index) => {
          return {
            index: `-${index}`,
            isInactive: false,
            isBooting: true,
            messages: [
              {
                type: 'warning',
                message:
                  'This worker is still booting it will be available soon (could take up to 5 min)...',
              },
              { message: `Started At: ${new Date(bootingWorker?.timestamp).toLocaleTimeString()}` },
              { message: `GPU ${bootingWorker?.gpuNames[0]}` },
            ],
            ...bootingWorker,
            account: state.user.accountSelected,
          }
        })
        .filter(({ workerId }) => !state.monitor.workers.find((w) => w.workerId === workerId))
    },
    accountMetrics(state) {
      const accountMetrics = {
        offlineWorkerCount: 0,
        currentDifficulty: 0,
        totalWorker: 0,
        totalGPU: 0,
        totalHashRate: 0,
        totalBlockCount: 0,
      }

      if (!state.monitor.workers.length) {
        return accountMetrics
      }

      return state.monitor.workers.reduce((total, worker) => {
        if (worker.isOutdated || worker.isOffline) {
          if (worker.isOffline) {
            total.offlineWorkerCount++
          }
          return total
        }

        if (worker.difficulty) {
          total.currentDifficulty = worker.difficulty
        }

        total.totalWorker++
        total.totalGPU += worker.hashRates.length
        total.totalHashRate += worker.totalHashRate
        total.totalBlockCount += worker.blocksFoundCount
        return total
      }, accountMetrics)
    },
    x1valOnline(state) {
      return state.x1valOnline
    },
  },
  mutations: {
    setSnackbar(state, { visible = true, text = '', timeout = 3 }) {
      state.snackbar.visible = visible
      state.snackbar.text = text
      state.snackbar.timeout = 1000 * timeout
    },
    setCurrentDifficulty(state, value) {
      state.currentDifficulty = `${value}K`
    },
    setUserAccounts(state, value = []) {
      state.user.accounts = value
    },
    SetUserAccountSelected(state, value) {
      state.user.accountSelected = value
    },
    setMonitorAccounts(state, value = []) {
      state.monitor.accounts = value
    },
    setMonitorAccountGPUStats(state, value = []) {
      state.monitor.gpuStats = value
    },
    setMonitorAccountDetails(state, value = []) {
      state.monitor.accountDetails = value
    },
    setNetworkDetails(state, value = {}) {
      state.networkDetails = value
    },
    setNetworkAccounts(state, value = []) {
      state.networkAccounts = value.filter(({ account }) => Web3Utils.isAddress(account))
    },
    setOfflineWorkerEnabled(state, value) {
      state.monitor.offlineWorkerEnabled = !!value
    },
    setWorkers(state, value = []) {
      const workers = []
      value.forEach((worker, index) => {
        const messages = []
        const {
          ACCOUNT,
          WORKER_ID,
          DIFFICULTY,
          HASH_RATES,
          BLOCKS_FOUND_COUNT,
          BLOCKS_FOUND_LATEST_PAYLOAD,
          BLOCKS_XUNI,
          BLOCKS_XENBLOCK,
          BLOCKS_SUPER,
          LATEST_BLOCKS_FOUND,
          PID_PYTHON3,
          PID_XENGPUMINER,
          STARTED_AT,
          TIMESTAMP,
          COMMIT_HASH,
          QUERY,
          DETAILS,
          VAST_DATA,
        } = worker

        const id = WORKER_ID?.split('-')?.[0] || ''
        if (workers.find(({ workerId }) => workerId === WORKER_ID)) {
          return // Ensure unique worker
        }

        const query = QUERY.split(',')
        const details = DETAILS.split('\n').map((detail) => detail.split(', '))
        const temperatures = details.map((detail) => parseInt(detail[5]))
        const clocks = details.map((detail) => parseInt(detail[14]))
        const clockMemories = details.map((detail) => parseInt(detail[13]))
        const gpusCount = details.length
        let gpus = details.map((detail) =>
          detail[0].replace('NVIDIA GeForce', '').replace('NVIDIA', '')
        )
        let gpuPowers = details.map((detail) => parseInt(detail[12])).sort((a, b) => a - b)
        let gpuUtilizations = details.map((detail) => parseInt(detail[7])).sort((a, b) => a - b)
        let gpuUtilizationMemories = details
          .map((detail) => parseInt(detail[8]))
          .sort((a, b) => a - b)
        let gpuMemoryTotals = details.map((detail) => parseInt(detail[9])).sort((a, b) => a - b)
        let gpuMemoryUseds = details.map((detail) => parseInt(detail[11])).sort((a, b) => a - b)
        gpus = [...new Set(gpus)].join(', ')

        const arraySum = (nums = []) => nums.reduce((total, num) => total + num, 0)

        const arrayToGBSum = (nums = [], precision = 0) =>
          parseFloat((nums.reduce((total, num) => total + num, 0) / 1024).toFixed(precision))

        const arrayAvg = (nums = []) =>
          parseInt(nums.reduce((total, num) => total + num, 0) / nums.length)

        const startedAtMS = STARTED_AT * 1000
        const startedAt = new Date(startedAtMS)
        const timestamp = new Date(TIMESTAMP * 1000)

        const isBooting =
          startedAtMS > Date.now() - 1000 * 60 * state.monitor.expireAfter && details.length === 0
        const isOutdated =
          timestamp.getTime() < Date.now() - 1000 * 60 * state.monitor.outdatedAfter
        const isOffline = timestamp.getTime() < Date.now() - 1000 * 60 * state.monitor.expireAfter

        if (isOffline) {
          messages.push({
            type: 'warning',
            message: `This worker has been offline for the last ${state.monitor.expireAfter} minutes.`,
          })
        } else if (isOutdated) {
          messages.push({
            type: 'warning',
            message: `This worker has been offline for the last ${state.monitor.outdatedAfter} minutes.`,
          })
        } else if (isBooting) {
          messages.push({
            type: 'warning',
            message: 'This worker is still booting it will be available soon...',
          })
        } else if (!DETAILS) {
          messages.push({
            type: 'warning',
            message:
              'Command nvidia-smi did not work yet, check your Nvidia drivers (Rebooting can sometimes solve this issue)',
          })
        }

        const hashRates = HASH_RATES.map((h) => parseInt(h))

        workers.push({
          index,
          id,
          account: ACCOUNT,
          workerId: WORKER_ID,
          difficulty: DIFFICULTY,
          hashRates,
          totalHashRate: Math.round(arraySum(HASH_RATES)),
          pidPython3: PID_PYTHON3,
          pidXengpuminer: PID_XENGPUMINER,
          blocksFoundCount: BLOCKS_FOUND_COUNT || 0,
          latestBlocksFound: new Date(LATEST_BLOCKS_FOUND * 1000).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          }),
          startedAtMS,
          startedAt,
          updatedAt: timestamp,
          updatedAtMS: timestamp.getTime(),
          timestamp: timestamp,
          since: Vue.filter('dateFormatDistanceToNow')(STARTED_AT * 1000),
          codeVersion: COMMIT_HASH,
          QUERY: QUERY,
          DETAILS: DETAILS,
          query,
          details,
          temperature: Math.max(...temperatures),
          gpus,
          gpusCount,
          gpusCountDisplayText:
            hashRates.length > 0 && gpusCount !== hashRates.length ? hashRates.length : gpusCount,
          gpuPower: arraySum(gpuPowers),
          gpuUtilization: arrayAvg(gpuUtilizations),
          gpuUtilizationMemory: arrayAvg(gpuUtilizationMemories),
          gpuMemoryTotal: arrayToGBSum(gpuMemoryTotals),
          gpuMemoryUsed: arrayToGBSum(gpuMemoryUseds),
          temperatures,
          gpuPowers: gpuPowers,
          gpuUtilizations: gpuUtilizations,
          gpuUtilizationMemories: gpuUtilizationMemories,
          gpuMemoryTotals: gpuMemoryTotals,
          gpuMemoryUseds: gpuMemoryUseds,
          clocks,
          clockMemories,
          messages,
          isBooting,
          isOutdated,
          isOffline,
          isInactive: isOutdated || isOffline,
          vastId: typeof VAST_DATA === 'string' ? VAST_DATA.split('\n')[0] : '',
          raw_data: { worker, messages },
          hasBlocksFoundLatestPayload: !!BLOCKS_FOUND_LATEST_PAYLOAD,
          xuni: BLOCKS_XUNI,
          xenblock: BLOCKS_XENBLOCK,
          superBlock: BLOCKS_SUPER,
          perfRatio: parseFloat(
            (
              (BLOCKS_XUNI + BLOCKS_XENBLOCK + BLOCKS_SUPER) /
              ((Date.now() - startedAt.getTime()) / 1000 / 60 / 60) /
              gpusCount
            ).toFixed(3)
          ),
        })

        workers.sort((a, b) => b.startedAtMS - a.startedAtMS)
      })
      state.monitor = { ...state.monitor, workers }
    },
    setBootingWorkers(state, value = []) {
      state.monitor.bootingWorkers = value
    },
    setX1valOnline(state, value = {}) {
      const now = new Date()

      value.data = value.data
        .slice(1, 10000)
        .filter((i) => i)
        .map(
          ({
            blockId,
            nodeAge,
            publicIp,
            timeMS,
            isp,
            country,
            avgRankHour,
            avgRankDay,
            avgRankWeek,
          }) => {
            const nAge = nodeAge.filter((v) => v).map((v) => parseInt(v))
            const age = {
              days: 0,
              hours: 0,
              minutes: 0,
            }
            if (nAge.length === 3) {
              age.days = nAge[0] || 0
              age.hours = nAge[1] || 0
              age.minutes = nAge[2] || 0
            }
            if (nAge.length === 2) {
              age.hours = nAge[0] || 0
              age.minutes = nAge[1] || 0
            }
            if (nAge.length === 1) {
              age.minutes = nAge[0] || 0
            }
            const hasCratedAt = nAge.length > 0
            const runtime = hasCratedAt ? age.days * 24 * 60 + age.hours * 60 + age.minutes : -1

            const createdAt = new Date(now)
            createdAt.setUTCMinutes(createdAt.getUTCMinutes() - runtime)

            return {
              blockId: parseInt(blockId[0]),
              publicIp: publicIp[0],
              hasCratedAt,
              createdAt,
              timeMS: timeMS[0],
              isp: isp[0],
              country: country[0],
              avgRankHour: parseInt(avgRankHour[0]),
              avgRankDay: parseInt(avgRankDay[0]),
              avgRankWeek: parseInt(avgRankWeek[0]),
            }
          }
        )
      state.x1valOnline = value
    },
  },
  actions: {
    async navigate(_, path) {
      return router.push(path).catch(console.error)
    },
    async fetchMonitorAccountDetails({ state, commit }) {
      const accountDetails = await axios
        .get(`${state.serverOrigin}/api/accounts/details`)
        .then(({ data }) => data || [])
        .catch(console.error)

      const monitorAccounts = Object.keys(accountDetails.stats || {}).map((account) => {
        return {
          account,
          ...accountDetails.stats[account],
        }
      })

      const gpuDetails = Object.entries(accountDetails?.gpuStats || {})
        .map(([gpuName, stats]) => {
          const count = stats.count ?? 0
          const totalHashRate = stats.hashRate ?? 0
          return {
            gpuName,
            count,
            totalHashRate: Math.round(totalHashRate),
            avgHashRate: Math.round(totalHashRate / count),
          }
        })
        .sort((a, b) => b.avgHashRate - a.avgHashRate)

      commit('setMonitorAccounts', monitorAccounts)
      commit('setMonitorAccountGPUStats', gpuDetails)
    },
    async fetchWorkers({ state, commit }, account) {
      const workers = await axios
        .get(`${state.serverOrigin}/api/${account}/meter-values`)
        .then(({ data }) => data || [])
        .catch(console.error)

      commit('setWorkers', workers)
    },
    async fetchBootingWorkers({ state, commit }, account) {
      const workers = await axios
        .get(`${state.serverOrigin}/api/booting/account/${account}`)
        .then(({ data }) => data || [])
        .catch(console.error)
      commit('setBootingWorkers', workers)
    },
    async fetchNetworkDetails({ commit }) {
      const networkDetails = await axios
        .get('https://xenblocks.io/v1/leaderboard?limit=1&offset=0')
        .then(({ data }) => data)
        .catch(console.error)

      commit('setNetworkDetails', {
        updatedAt: new Date(),
        miningBlockRate: networkDetails.totalHashRate || 0,
        currentMiners: networkDetails.totalMiners || 0,
        networkDifficulty: networkDetails.difficulty || 0,
        totalBlocks: (((networkDetails.totalBlocks || 0) * 10) / 1000000).toFixed(2),
      })
    },
    async fetchNetworkAccounts({ commit }) {
      // const networkDetails = await axios
      //   .get('https://xenblocks.io/v1/leaderboard?limit=1&offset=0')
      //   .then(({ data }) => data)
      //   .catch(console.error)
      //
      // commit('setNetworkDetails', {
      //   updatedAt: new Date(),
      //   miningBlockRate: networkDetails.totalHashRate || 0,
      //   currentMiners: networkDetails.totalMiners || 0,
      //   networkDifficulty: networkDetails.difficulty || 0,
      //   totalBlocks: (((networkDetails.totalBlocks || 0) * 10) / 1000000).toFixed(2),
      // })
      //
      // const accounts = networkDetails.miners.map((miner) => {
      //   return {
      //     account: miner.account,
      //     total_blocks: miner.blocks,
      //     total_xuni: null,
      //     super_blocks: miner.superBlocks,
      //     rank: miner.rank,
      //   }
      // })
      //
      // commit('setNetworkAccounts', accounts)
    },
    async fetchNetworkAccount({ commit }, account) {
      const accountDetail = await axios
        .get(`https://xenblocks.io/v1/leaderboard/${account}`)
        .then(({ data }) => data)
        .catch(console.error)

      if (accountDetail) {
        const accounts = [
          {
            account,
            total_blocks: accountDetail.blocks,
            total_xuni: null,
            super_blocks: accountDetail.superBlocks,
            rank: accountDetail.rank,
          },
        ]

        commit('setNetworkAccounts', accounts)
      }
    },
    async deleteWorker({ state, commit, dispatch }, { account, workerId }) {
      const { status } = await axios
        .delete(`${state.serverOrigin}/api/${account}/${workerId}`)
        .catch((e) => {
          console.error(e)
          return {}
        })

      const message = status >= 200 && status < 300 ? 'Delete success' : `Delete error ${status}`
      commit('setSnackbar', { text: message })

      return dispatch('fetchWorkers', account)
    },
    async deleteBootingWorker({ state, commit, dispatch }, { account, workerId }) {
      const { status } = await axios
        .delete(`${state.serverOrigin}/api/booting/${account}/${workerId}`)
        .catch((e) => {
          console.error(e)
          return {}
        })

      const message = status >= 200 && status < 300 ? 'Delete success' : `Delete error ${status}`
      commit('setSnackbar', { text: message })

      return dispatch('fetchBootingWorkers', account)
    },
    async fetchX1valOnline({ state, commit }) {
      const x1valOnline = await axios
        .get(`${state.serverOrigin}/x1-node/x1val-online.json`)
        .then(({ data }) => data || {})
        .catch(console.error)
      commit('setX1valOnline', x1valOnline)
    },
    async fetchDifficulty({ state, commit }) {
      const response = await axios
        .get(`${state.serverOrigin}/api/network/difficulty`)
        .then(({ data }) => data || {})
        .catch(console.error)
      commit('setCurrentDifficulty', response?.difficulty || 0)
    },
    onClipboard({ commit }, { value, message = 'Copied to clipboard' }) {
      if (typeof value === 'object') {
        value = JSON.stringify(value)
      }
      navigator.clipboard.writeText(value)
      if (message) {
        commit('setSnackbar', { text: message })
      }
    },
  },
})
