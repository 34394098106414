import Vue from 'vue'
import { getCurrentInstance } from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: { dark: true },
  options: {
    themeCache: {
      get: (key) => localStorage.getItem(key),
      set: (key, value) => localStorage.setItem(key, value),
    },
  },
})

export const useVuetify = () => {
  const vm = getCurrentInstance()
  return vm.proxy?.$vuetify || undefined
}
