<template>
  <div id="app">
    <v-app>
      <v-main>
        <router-view />
        <v-snackbar v-model="snackbarVisible" :timeout="snackbar.timeout">
          <div class="text-center">
            <strong>{{ snackbar.text }}</strong>
          </div>
        </v-snackbar>
        <br />
        <br />
        <div style="position: absolute; bottom: 15px; right: 50px">
          {{ appBuild.version }}, {{ appBuild.createAt | dateFormat }}
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

const appBuild = {
  version: process.env.VUE_APP_BUILD_VERSION,
  createAt: process.env.VUE_APP_BUILD_CREATED_AT,
}

export default {
  name: 'MainApp',
  beforeCreate() {
    caches?.keys()?.then(function (names) {
      try {
        for (let name of names) {
          caches.delete(name)
        }
      } catch (e) {
        console.error(e)
      }
    })
  },
  async created() {
    await this.fetchDifficulty()
    await this.fetchNetworkDetails()
    // await this.fetchNetworkAccounts()
  },
  data() {
    return {
      appBuild,
    }
  },
  computed: {
    ...mapGetters(['snackbar']),
    snackbarVisible: {
      get() {
        return this.snackbar.visible
      },
      set(value) {
        this.setSnackbar({ visible: value })
      },
    },
  },
  methods: {
    ...mapActions(['fetchNetworkDetails', 'fetchNetworkAccounts', 'fetchDifficulty']),
    ...mapMutations(['setSnackbar']),
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
